import { AuthUser } from 'next-firebase-auth'
import { Team, UserProfile } from 'src/types'

import { AccountCore, AccountProtectedFields } from './AccountCore'
import { Onboarding, onboarding } from './features/onboarding'

export class Account extends AccountCore {
  public onboarding: Onboarding

  constructor(authUser: AuthUser, userProfile: UserProfile, team: Team | null) {
    super(authUser, userProfile, team)

    const fields: AccountProtectedFields = {
      authUser,
      userProfile,
      team,
      plan: this.plan,
    }

    this.onboarding = onboarding(this, fields)
  }
}
