import {
  InterAppMessage,
  InterAppMessageResponse,
} from '@arcadehq/shared/types'
import { captureException } from '@sentry/nextjs'
import { AuthUser } from 'next-firebase-auth'
import { Flow } from 'src/types'

import {
  localChromeExtensionId,
  publishedChromeExtensionId,
} from '../../constants'
import { getFlowDataDocFromFlow } from './flows'

export const openOrSwitchToEditorTab = async (flowId: string) => {
  const result = await sendMessage({
    name: 'OpenOrSwitchToEditorTab',
    flowId,
  })

  if (result.success) {
    window.close()
  }
}

export const passUserToExtension = async (
  user: Partial<AuthUser> | null
): Promise<boolean> => {
  const result = await sendMessage({
    name: 'UserFromApp',
    user,
  })
  return result.success
}

export const notifyExtensionReadyForPreflight = async (): Promise<boolean> => {
  const result = await sendMessage({
    name: 'ReadyForPreflight',
  })

  if (!result.success) {
    captureException(new Error(result.errorMessage))
    return false
  }

  return true
}

export const getExtensionVersion = async (): Promise<string | null> => {
  const result = await sendMessage({
    name: 'GetVersion',
  })

  if (result.success) {
    return result.reply.version || null
  }

  return null
}

export const passLastEditedFlowToExtension = async (flow: Flow) => {
  await sendMessage({
    name: 'LastEditedFlow',
    flow: getFlowDataDocFromFlow(flow),
  })
}

//
// Internal helpers
//

type Response<Message extends InterAppMessage> =
  | { success: true; reply: InterAppMessageResponse<Message['name']> }
  | { success: false; errorMessage: string }

// We try to send the message to the published extension first, then the local
// extension. This allows us to test the prod extension locally and vice versa.
const sendMessage = async <M extends InterAppMessage>(
  message: M
): Promise<Response<M>> => {
  const prodResult = await sendChromeExtensionMessage(
    publishedChromeExtensionId,
    message
  )
  if (prodResult.success) {
    return prodResult
  }

  const devResult = await sendChromeExtensionMessage(
    localChromeExtensionId,
    message
  )
  if (devResult.success) {
    return devResult
  }

  return {
    success: false,
    errorMessage: `Failed to send ${message.name} to extension: ${prodResult.errorMessage} - ${devResult.errorMessage}`,
  }
}

const sendChromeExtensionMessage = <Message extends InterAppMessage>(
  extensionId: string,
  message: Message
): Promise<Response<Message>> =>
  new Promise(resolve => {
    if (typeof window === 'undefined') {
      return resolve({
        success: false,
        errorMessage: 'window is not defined',
      })
    }

    const w = window as any
    if (
      typeof w !== 'undefined' &&
      'chrome' in w &&
      'runtime' in w.chrome &&
      'sendMessage' in w.chrome.runtime
    ) {
      w.chrome.runtime.sendMessage(extensionId, message, (reply: any) => {
        if (typeof reply === 'undefined') {
          resolve({
            success: false,
            errorMessage: w.chrome.runtime.lastError,
          })
        } else {
          resolve({
            success: true,
            reply,
          })
        }
      })
    } else {
      resolve({
        success: false,
        errorMessage: 'chrome.runtime.sendMessage not found',
      })
    }
  })
