import { Step, StepType } from '@arcadehq/shared/types'

export function getStepPreviewUrl(step: Step | undefined): string {
  if (!step) {
    return '/images/empty-flow.svg'
  }
  if (step.type === StepType.Image) {
    return step.url || step.imageUrl || '/images/empty-flow.svg'
  }

  if (step.type === StepType.Video) {
    return (
      step.videoThumbnailUrl ||
      getThumbnailUrlFromVideoStreamUrl(step.streamUrl || step.url)
    )
  }

  return '/images/empty-flow.svg'
}

export function getThumbnailUrlFromVideoStreamUrl(streamUrl?: string): string {
  return (
    streamUrl
      ?.replace('stream', 'image')
      .replace('.m3u8', '/thumbnail.png?time=0') ?? ''
  )
}

export function getStepName(
  step: Step | undefined = undefined,
  index: number = 0
) {
  if (!step) {
    return ''
  }
  let title = `Step ${index + 1}`
  if (step.title) {
    title = `${step.title}`
  } else if (step.hotspots.length > 0 && step.hotspots[0].label) {
    title = `${step.hotspots[0].label}`
  }
  return title
}

export function isStepWithSize(step: Step | undefined) {
  return step?.type === StepType.Image || step?.type === StepType.Video
}
