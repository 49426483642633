import { AuthUser } from 'next-firebase-auth'
import { UserProfile } from 'src/types'

import { Account } from './Account'

// Used only in the default value for AuthContext
class DefaultAccount extends Account {
  constructor() {
    const defaultAuthUser: AuthUser = {
      id: null,
      email: null,
      emailVerified: false,
      phoneNumber: null,
      displayName: null,
      photoURL: null,
      claims: {},
      getIdToken: () => Promise.resolve(null),
      clientInitialized: false,
      firebaseUser: null,
      signOut: () => Promise.resolve(),
    }

    const defaultUserProfile: UserProfile = {
      id: '',
      created: new Date(0),
      modified: new Date(0),
      createdBy: '',
      lastModifiedBy: '',
      update: () => Promise.resolve(false),
      delete: () => Promise.resolve(),
      isFreeUser: true,
      isProUser: false,
    }

    const defaultTeam = null

    super(defaultAuthUser, defaultUserProfile, defaultTeam)
  }
}

const defaultAccount = new DefaultAccount()
export default defaultAccount
