import { captureException, withScope } from '@sentry/nextjs'
import type { Extras } from '@sentry/types'
import type { AxiosError } from 'axios'
import omit from 'lodash/omit'
import { isProductionEnv } from 'src/helpers'

const isAxiosError = (e: unknown): e is AxiosError => {
  return (
    typeof e === 'object' &&
    e !== null &&
    'isAxiosError' in e &&
    (e as any).isAxiosError
  )
}

const jsonParseIfPossible = (input: string): string | object => {
  try {
    return JSON.parse(input)
  } catch (_e) {
    return input
  }
}

const headersToOmit = ['authorization', 'Authorization']

export const captureExceptionWithRequestData = (e: unknown) => {
  if (isAxiosError(e)) {
    const extras: Extras = {}
    if (e.config.headers) {
      extras['request_headers'] = omit(e.config.headers, headersToOmit)
    }
    if (e.config.params) {
      extras['request_params'] = e.config.params
    }
    if (e.config.data) {
      extras['request_data'] = jsonParseIfPossible(e.config.data)
    }
    if (e.response) {
      extras['response_data'] = e.response.data
      extras['response_headers'] = omit(e.response.headers, headersToOmit)
      extras['response_status'] = e.response.status
      extras['response_status_text'] = e.response.statusText
    }
    withScope(scope => {
      scope.setExtras(extras)
      captureException(e)
    })
  } else {
    captureException(e)
  }
}

export function captureError(...args: any) {
  const ctx = args.slice(1)
  if (!isProductionEnv()) {
    // eslint-disable-next-line no-console
    console.error(...args)
  }

  withScope(scope => {
    if (ctx.length > 0) {
      scope.setContext('Context', ctx)
    }
    captureException(args[0])
  })
}
